.iframe-view {
  width: 100%;
  height: 90%;
  border: 1px solid lightgray;
}

.pause-resume-btn-grp{
  padding-right: 10px
}

#record-btn,
#pause-record-btn {
  /* background styles */
  background-color: #b85f57;
  background-image: linear-gradient(#b85f57, #da1100);

  /* text styles */
  color: #fff;
  height: 45px;
  text-transform: none;
}

#extendPause,
#pause-record-btn {
  /* background styles */
  background-color: green;
  background-image: linear-gradient(green, rgb(2, 150, 2));

  /* text styles */
  color: white;
  height: 45px;
  text-transform: none;
}

#extendPause:after,
#record-btn:after {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: calc(100% - 4px);
  height: 50%;
  background: linear-gradient(
    rgba(248, 181, 181, 0.801),
    rgba(255, 255, 255, 0.2)
  );
}

#extendPause:after,
#pause-record-btn:after {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: calc(100% - 4px);
  height: 50%;
  background: linear-gradient(rgb(180, 241, 180), rgba(255, 255, 255, 0.2));
}
